import { Nullable } from './Nullable'

export enum CostPlanType {
  UNKNOWN = 'UNKNOWN',
  PRIVATE = 'PRIVATE',
  KFO = 'KFO',
  ZE = 'ZE',
  KBR = 'KBR',
  PAR = 'PAR',
  IMP = 'IMP',
  FAL = 'FAL',
  ZMK = 'ZMK',
  GTR = 'GTR',
  KASSE_PAR_STATUS = 'KASSE_PAR_STATUS',
  EBZ_ZE = 'EBZ_ZE',
  EBZ_KGL = 'EBZ_KGL',
}

export enum CostPlanStatus {
  UNDEFINED = 'UNDEFINED',
  CREATED = 'CREATED',
  PRINTED = 'PRINTED',
  DECLINED = 'DECLINED',
  TO_REVISE = 'TO_REVISE',
  ACCEPTED = 'ACCEPTED',
  DISCARDED = 'DISCARDED',
  ACCOMPLISHED = 'ACCOMPLISHED',
  INSTANT_BILLING = 'INSTANT_BILLING',
  CHARGED = 'CHARGED',
}

export type CostPlan = {
  externalId: Nullable<string>
  referenceId: string
  name: string
  clinicReferenceId: string
  clinicOfficialName: string
  practitionerReferenceId: Nullable<string>
  practitionerDisplayName: string
  patientReferenceId: Nullable<string>
  type: Nullable<CostPlanType>
  start: string
  status: CostPlanStatus
  statusChangedAt: Nullable<string>
  totalCosts: number
  costsToBePaidByPatient: number
  numberOfInstallmentsInMonths: number
  isFinancingAvailable: boolean
}
