import { useAnamnesisPerClinic } from './api/useAnamnesisPerClinic'

export const useAnamnesisEvaluation = ({
  enabled,
  useErrorBoundary = false,
}: {
  useErrorBoundary?: boolean
  enabled?: boolean
}) => {
  const { data: activeData, isLoading: activeLoading } = useAnamnesisPerClinic({ enabled, useErrorBoundary })
  const { data: deprecatedData, isLoading: deprecatedLoading } = useAnamnesisPerClinic({
    enabled,
    useErrorBoundary,
    onlyDeprecated: true,
    includeDeprecated: true,
  })

  const active = Object.values(activeData || {}).sort(
    (a, b) => Number(new Date(b.createdAtDateTime)) - Number(new Date(a.createdAtDateTime))
  )

  const expired = active
    .filter((ana) => ana.isExpired || (ana.anamnesisReferenceId && !ana.isValid))
    .map((el) => el.anamnesisReferenceId)

  const missing = active.filter((ana) => !ana.anamnesisReferenceId).map((el) => el.clinicReferenceId)

  const problemWithFactoring = active.filter((ana) => {
    const { signed, expired, valid } = ana.latestFactoringAgreementForClinic || {}

    return ana.isValid && (expired || !signed || !valid)
  })

  return {
    isLoading: activeLoading || deprecatedLoading,
    mostRecentValidAna: active.filter((a) => a.isValid).find((a) => a),
    all: active || [],
    deprecated: Object.values(deprecatedData || {}) || [],
    expired: expired || [],
    missing: missing || [],
    problemWithFactoring: problemWithFactoring || [],
  }
}
