import { useQuery } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useAnamnesisPerClinic = ({
  useErrorBoundary,
  enabled = true,
  includeDeprecated = false,
  onlyDeprecated = false,
}: {
  useErrorBoundary?: boolean
  includeDeprecated?: boolean
  onlyDeprecated?: boolean
  enabled?: boolean
}) => {
  const personReferenceId = usePersonId()

  return useQuery(
    ['anamnesis_listing', personReferenceId, includeDeprecated, onlyDeprecated],
    () =>
      api.ana.getAnamnesisListing({
        personReferenceId,
        includeDeprecated,
        onlyDeprecated,
      }),
    {
      enabled: Boolean(personReferenceId) && enabled,
      cacheTime: 5 * 60 * 1000,
      useErrorBoundary,
    }
  )
}
